//
//  // faq sass

.faq-h2 {
    width:                          250px; }
.faq-ul {
    margin-top:                     4rem; }

.faq-li {
    border-top:                     1px solid $alttextcolor;
 }    // margin-top:                     0
.faq-a {
    background:                     rgba(225, 247, 252, 0.98);
    padding:                        0.7rem 1.5rem;
    font-size:                      $microfont;
    color:                          #333333;
    @media #{ $xsmallup } {
        padding:                    1.5rem; } }
.uk-accordion>:nth-child(n+2) {
    margin-top:                     0; }

.faq-content-container {
    background:                     rgba(248, 255, 253, 0.97);
    margin-top:                     0;
    overflow:                       hidden;
    height:                         0;
    transition:                     height 0.2s ease-out;
    p {
        font-size:                  $microfont;
        padding:                     2rem; } }

.uk-accordion-title::before {
    content:                        "";
    margin-left:                    0;
    float:                          right;
    background-image:               url(/uploads/down.svg);
    background-repeat:              no-repeat;
    background-position:            50% 50%; }
.title-open::before {
    background-image:               url(/uploads/up.svg); }

.uk-open>.uk-accordion-title::before {
        background-image:           url(/uploads/up.svg); }

.faq-form {
    margin:                         3.5rem 0;
    border-bottom:                  1px solid $black;
    padding-bottom:                 0.3rem;
    input {
        border:                     none;
        outline:                    none;
        color:                      $readablegray;
        font-size:                  $microfont;
        width:                      80%;
        &::placeholder {
            color:                  $readablegray;
            font-size:              $microfont; }
        &::-ms-clear {
            display:                none;
            width:                  0;
            height:                 0; } } }


