//
//// Mobiilenav
.nav-bar-mobile {
    padding:                        1rem 0.5rem;
    @media #{ $smallup } {
        padding:                    0; } }

#menuToggle {
    display:                        block;
    position:                       absolute;
    top:                            45px;
    right:                          23px;
    z-index:                        1;
    -webkit-user-select:            none;
    user-select:                    none; }


#menuToggle input {
    display:                        block;
    width:                          40px;
    height:                         32px;
    position:                       absolute;
    top:                            -7px;
    left:                           -5px;
    cursor:                         pointer;
    opacity:                        0;
    z-index:                        2;
    -webkit-touch-callout:          none; }


#menuToggle span {
    display:                        block;
    width:                          24px;
    height:                         3px;
    margin-bottom:                  5px;
    position:                       relative;
    background:                     #979696;
    border-radius:                  3px;
    z-index:                        1;
    transform-origin:               4px 0px;
    transition:                     transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease; }


#menuToggle span:last-child {
    transform-origin:               0% 100%; }


#menuToggle input:checked ~ span {
    opacity:                        1;
    transform:                      rotate(45deg) translate(0px, 2px);
    background:                     #979696; }


#menuToggle input:checked ~ span:nth-last-child(2) {
    opacity:                        1;
    transform:                      rotate(-45deg) translate(-6px, 0); }


#menu {
    position:                       fixed;
    width:                          100%;
    height:                         100vh;
    background:                     #F1FCFF;
    -webkit-backdrop-filter:        blur(5px);
    top:                            0;
    left:                           -100%;
    text-align:                     center;
    transition:                     all .5s;
    padding-left:                   0px; }

#menu li {
    display:                        block;
    margin:                         50px 0;
    line-height:                    30px; }

#menuToggle input:checked ~ ul {
    left:                           0; }

.mobile-social-li {
    a {
        margin-right:               $space-sm;
        &:last-child {
                margin-right:       0; } }
    svg path {
        fill:                           $black; } }


