//
//   // Typography

$basefont:              15.795px;
$picofont:              0.795rem;
$nanofont:              0.925rem;
$microfont:             1.025rem;
$smallfont:             1.215rem;
$mediumfont:            1.325rem;
$largefont:             1.475rem;
$largerfont:            2rem;

$defaultlh:             1.5725;
$headinglh:             1.2925;
$headingls:             1.12px;

$bodyfont:              DMSanRegular;
$headingfont:           DMSerifDisplay;
$secondheadingfont:     DMSerifDisplay;
$xbold:                 900;
$bold:                  700;
$medium:                600;
$normal:                400;
$thin:                  300;

// Colors
$primarycolor:          #F8FFFD;
$alttextcolor:          #2F2F2F;
$readablegray:          #979696;
$readableblue:          #0FAFF0;
$sweetgray:             #999999;
$platinum:              #f5f5f5;
$black:                 #000000;
$white:                 #ffffff;


// Media
$borderline:            1px solid #eee;
$linkColorTransition:   color 0.32s ease-out;
$ease-in-timing:        720ms ease-in both;
$subtle-box-shadow:     0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 1px -2px rgba(0, 0, 0, 0.1);

// Measures & Widths
$maxwidth-lg:           1024px;
$maxwidth-lt:           992px;
$maxwidth-tb:           768px;
$maxwidth-md:           642px;
$maxwidth-sm:           544px;
$maxwidth-xs:           411px;
$maxwidth-xxs:          360px;

$space-xxs:             0.32rem;
$space-xs:              0.64rem;
$space-sm:              1.56rem;
$space-md:              2.64rem;
$space-lg:              3.56rem;
$space:                 1.24rem;
$p-margin-bottom:       25px;

// Media Queries
$xxsmallup:              "only screen and (min-width: 368px)";
$xsmallup:               "only screen and (min-width: 425px)";
$xsmallupnew:            "only screen and (min-width: 450px)";
$smallup:                "only screen and (min-width: 544px)";
$smediumup:              "only screen and (min-width: 672px)";
$mediumup:               "only screen and (min-width: 768px)";
$slargeup:               "only screen and (min-width: 970px)";
$largeup:                "only screen and (min-width: 992px)";
$slaptop:                "only screen and (min-width: 1024px)";
$mlaptop:                "only screen and (min-width: 1254px)";
$laptop:                 "only screen and (min-width: 1440px)";
$laptopup:               "only screen and (min-width: 1920px)";
