//
//  // Trumps/Utilities

// Widths
.width-100-pc {
    width:              100%; }


// Display
.b {
    color: red; }
.d-blk {
    display:            block; }
.d-ibl {
    display:            inline-block; }

.d-flx {
    display:            flex; }
.d-ifx {
    display:            inline-flex; }

.flex-dir-col {
    flex-direction:     column; }

.al-i-c {
    align-items:        center; }

.j-c-sb {
    justify-content:    space-between; }
.j-c-fe {
    justify-content:    flex-end; }


// Margins
.m-0 {
    margin:             0; }
.m-auto {
    margin:             auto; }
.my-0 {
    margin-top:         0;
    margin-bottom:      0; }

.my-2 {
    margin-top:         2rem;
    margin-bottom:      2rem; }

.mt-0 {
    margin-top:         0; }
.mt-h {
    margin-top:         0.5rem; }
.mt-1 {
    margin-top:         1rem; }
.mt-1h {
    margin-top:         1.5rem; }
.mt-2 {
    margin-top:         2rem; }
.mt-2h {
    margin-top:         2.5rem; }
.mt-3 {
    margin-top:         3rem; }

.mr-h {
    margin-right:       0.5rem; }
.mr-1 {
    margin-right:       1rem; }

.mb-0 {
    margin-bottom:      0; }
.mb-h {
    margin-bottom:      0.5rem; }
.mb-1 {
    margin-bottom:      1rem; }
.mb-2 {
    margin-bottom:      2rem; }

.ml-1 {
    margin-left:        1rem; }


// Paddings
.pt-h {
    padding-top:        0.5rem; }
.pt-1 {
    padding-top:        1rem; }
.pt-2 {
    padding-top:        2rem; }
.pt-3 {
    padding-top:        3rem; }

.py-1 {
    padding-top:        1rem;
    padding-bottom:     1rem; }
.py-2 {
    padding-top:        2rem;
    padding-bottom:     2rem; }

.pb-0 {
    padding-bottom:     0; }
.pb-h {
    padding-bottom:     0.5rem; }
.pb-1 {
    padding-bottom:     1rem; }
.pb-1h {
    padding-bottom:     1.5rem; }

// Floats
.fl-r {
    float:              right; }
.fl-l {
    float:              left; }

// Clearfix Hack
.clearfix {
    &::after {
        content:        '';
        display:        table;
        clear:          both; } }


// Other
.pointer-events-none {
    pointer-events:     none; }

.indented {
    display:            block;
    text-indent:        $space; }

.italicized {
    font-style:         italic; }


// Screen Reader Text
//  // The .sr class ensure element is available in the DOM but only to screen readers. The whitespace: nowrap rule ensures accessible texts aren't smushed off screen.
//  // @link https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
.sr:not(:focus):not(:active) {
    position:           absolute;
    width:              1px;
    height:             1px;
    padding:            0;
    margin:             -1px;
    border:             0;
    clip-path:          inset(100%);
    overflow:           hidden;
    white-space:        nowrap; }
