//
//  // Navigations
.nav {
    max-width:                      1100px;
    margin:                         0 auto;
    @media #{ $smallup } {
        padding:                    2rem; }
    @media #{ $slargeup } {
        padding:                    3rem 3rem;
        margin-bottom:              2rem; }
    @media #{ $mlaptop } {
        padding:                    2rem 5rem; } }
