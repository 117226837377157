//
//  // About Page

.about-container {
    padding:                        2rem 0.5rem 0 0.5rem;
    max-width:                      1100px;
    margin:                         0 auto;
    @media #{ $smallup } {
        padding:                    2rem 1.5rem 0 1.5rem; }
    @media #{ $slargeup } {
        padding:                    2rem 5rem 0 5rem; }
    @media #{ $mlaptop } {
        padding:                    2rem 7rem 0 7rem; } }
.about-container-1 {
    margin-bottom:                  2rem; }
.about-image {
    border:                         10px solid #E3F7FC;
    margin-top:                     1rem;
    margin-bottom:                  2rem;
    @media #{ $mediumup } {
        margin-bottom:              3rem;
        border:                     20px solid #E3F7FC; } }

.about-p {
    color:                          #333333;
    line-height:                    2;
    text-align:                     justify;
    font-size:                      $microfont; }

.about-h2 {
    color:                          $alttextcolor;
    line-height:                    2; }



// our team
.team-container {
    margin:                         3rem 0 !important;
    &.with-icons {
        margin-bottom:              2rem !important; }

    .icons {
        text-align:                 center;
        align-items:                center;
        margin-top:                 50px;
        @media only screen and (max-width: 900px) {
            flex-direction:         column; }
        img {
            margin:                 0 auto 20px;
            width:                  150px;
            @media only screen and (max-width: 900px) {
                width:              200px;
                margin:             55px auto 10px; } } }

    @media #{ $smallup } {
        margin:                     5rem 0; } }

.team-h2 {
    width:                          300px;
 }    // line-height:                    2

.team-grid-container {
    width:                          257px;
    margin:                         2rem auto;
    text-align:                     center;
    @media #{ $smallup } {
        width:                      auto;
        margin:                     2rem 0;
        display:                    grid;
        grid-template-columns:      257px 257px;
        grid-gap:                   10px;
        justify-content:            space-evenly; }
    @media #{ $slargeup } {
        grid-template-columns:      repeat(3, 257px);
        grid-gap:                   3rem; }
    @media #{ $mlaptop } {
        grid-gap:                   3.4rem; } }

.team-grid-item {
    margin-bottom:                  2.5rem;
    @media #{ $smallup } {
        &:nth-of-type(3) {
            grid-column:            1/3;
            justify-self:           center;
            @media #{ $slargeup } {
                grid-column:        3;
                justify-self:       start; } } } }


.team-grid-img {
    border:                         7px solid #E3F7FC; }
.team-name {
    font-size:                      $microfont;
    margin-top:                     2rem;
    margin-bottom:                  1rem; }
.team-title {
    font-size:                      $picofont; }


.launch {
    @media only screen and (max-width: 620px) {
        flex-direction:    column; }
    &>div {
        &:first-of-type {
            flex:           0 0 49%; }
        &:last-of-type {
            object-fit:     contain;
            flex:           0 0 49%;
            @media only screen and (max-width: 620px) {
                margin-top: 2rem; } } }

    img {
        margin:             auto; } }


.grow {
    margin-top:                 4rem;
    .wrap {
        &>div {
            &:first-of-type {
                flex:           0 0 49%; }
            &:last-of-type {
                object-fit:     contain;
                @media only screen and (max-width: 620px) {
                    margin:     1rem auto 3rem; } } }
        @media only screen and (max-width: 620px) {
            flex-direction:    column; } }
    .img-box {
            padding-left: 10px;
            flex:               0 0 49%; }
    .more {
        flex-wrap:             wrap;
        margin:                20px 40px;
        @media only screen and (max-width: 750px) {
            margin:             0;
            text-align:         center; }
        @media only screen and (max-width: 650px) {
            p {
                flex:           0 0 50%; } }
        @media only screen and (max-width: 350px) {
            p {
                flex:           0 0 100%; } } }
    .button {} }


.build, .analytics {
    margin-top:         4rem; }

.build {
    .home-section-h2 {
        br {
            @media only screen and (max-width: 500px) {
                display:        none; } } }
    &>p {
        &:first-of-type {
            margin-top:         1.5rem; }
        &:last-of-type {
            color:              $readablegray;
            margin-bottom:      5px !important;
            br {
                @media only screen and (max-width: 500px) {
                    display:     none; } } } }
    .icons {
        margin-bottom:          1rem;
        &>div {
            p {
                margin-top:     2rem; } } } }



.patients-page {
    .home-section-h2 {
        position:               relative;
        z-index:                1;
        @media only screen and (max-width: 784px) {
            position:       unset; }
        br {
            @media only screen and (max-width: 444px) {
                display:    none; } } }
    .about-image {
        border:                 none !important;
        margin-top:             -8rem !important;
        @media only screen and (max-width: 800px) {
            margin-top:         -.5rem !important; } }
    .icons {
        text-align:             center;
        @media only screen and (max-width: 900px) {
            margin-top:         0; } }

    .treatment {
        &>div {
            @media only screen and (max-width: 900px) {
                flex-wrap:      wrap; }
            @media only screen and (max-width: 700px) {
 }                //    flex-direction: column

            div {
                ul {
                    list-style-type:        square;
                    line-height:            1.8;
                    @media only screen and (max-width: 700px) {
                        margin-bottom:      0; }
                    li {
                        color:              #2F2F2F;
                        font-size:          15.795px; } }
                &:not(:last-of-type) {
                    @media only screen and (min-width: 900px) {
                        margin-right:       3rem; }
                    @media only screen and (max-width: 900px) {
                        flex:   0 0 31%; }
                    @media only screen and (max-width: 700px) {
                        flex:   0 0 100%; } } } } } }
.faq-section {
    .pricing-container1 {
        border-bottom:             none;
        margin-bottom:             -2rem; }
    .home-section-h2 {
        margin-bottom:      0;
        padding-bottom:     0; }
    ul {
        list-style:                none;
        padding:                   0;
        div {
 } } }             //padding-top:           1.5rem


