//
//      // Wrappers
.linear-gradient-background {
    background:             linear-gradient(240.98deg, rgba(23, 137, 252, 0) -20.26%, rgba(23, 137, 252, 0.15) 63.81%); }


// Measures & Widths
.maxwidth-lg {
    max-width:                  $maxwidth-lg; }
.maxwidth-lt {
    max-width:                  $maxwidth-lt; }
.maxwidth-md {
    max-width:                  $maxwidth-md; }
.maxwidth-tb {
    max-width:                  $maxwidth-tb; }
.maxwidth-sm {
    max-width:                  $maxwidth-sm; }
.maxwidth-xs {
    max-width:                  $maxwidth-xs; }
.maxwidth-xxs {
    max-width:                  $maxwidth-xxs; }

.width-md-mediumup {
    @media #{ $mediumup } {
        width:                  $maxwidth-md; } }
.width-xxs-mediumup {
    @media #{ $mediumup } {
        width:                  $maxwidth-xxs; } }

.mx-auto {
    margin:                     0 auto; }
