//
//      // Links

.link {
    font-family:        $headingfont;
    font-weight:        $medium; }

.alt-link {
    color:              $black;
    &:focus, &:hover, &.active {
        color:          #0FAFF0;
        text-decoration: none;
        font-weight:    bold;
        border-bottom:  2px solid #0FAFF0; } }




