//
//      // footer
.footer-container {
        background:                                     $alttextcolor;
        margin-top:                                     4rem; }

.footer-container2 {
        padding:                                        1rem 0.5rem 0 0.5rem;
        max-width:                                      1100px;
        margin:                                         0 auto;
        .footer-icons {
                margin-bottom:                             45px; }
        @media #{ $smallup } {
                padding:                                1rem 1.5rem 0 1.5rem; }
        @media #{ $slargeup } {
                padding:                                2rem 3rem; }
        @media #{ $mlaptop } {
                padding:                                2rem 5rem; } }


.item-e {
        text-align:                             center;
        margin-top:                             2rem;
        @media #{ $mediumup } {
                margin-top:                     0;
                grid-column:                    1/3;
                justify-self:                   center; } }
.footer-social {
        display:                                flex;
        flex-wrap:                              wrap;
        float:                                  right;
        margin-bottom:                          3rem;
        margin-top:                             1.4rem;
        @media #{ $mediumup } {
                justify-self:                   end;
                float:                          none; }
        li {
                margin-right:                   $space-sm;
                &:last-child {
                        margin-right:           0; } } }
.footer-text-links {
        margin:                                 4rem auto 0;
        width:                                  100%;
        flex-wrap:                              wrap;
        div {
                &:first-of-type {}
                &:nth-of-type(2) {
                        transform:      translateX(-70%);
                        @media only screen and (max-width: 1200px) {
                                transform:      translateX(-90%); }
                        @media only screen and (max-width: 1050px) {
                                transform:      translateX(-70%); }
                        @media only screen and (max-width: 900px) {
                                transform:      translateX(-50%); }
                        @media only screen and (max-width: 850px) {
                                transform:      translateX(-24%); }
                        @media only screen and (max-width: 750px) {
                                transform:      translateX(-10%); }
                        @media only screen and (max-width: 716px) {
                                transform:      translateX(-170%); }
                        @media only screen and (max-width: 640px) {
                                transform:      translateX(-130%); }
                        @media only screen and (max-width: 570px) {
                                transform:      translateX(-90%); }
                        @media only screen and (max-width: 500px) {
                                transform:      translateX(-50%); }
                        @media only screen and (max-width: 420px) {
                                transform:      translateX(-20%); }
                        @media only screen and (max-width: 375px) {
                                transform:      translateX(0); } }
                &:last-of-type {
                        @media only screen and (max-width: 716px) {
                                flex:           0 0 100%; } } }


        @media #{ $mediumup } {
                margin:                         0; } }
.footer-p {
        color:                                  $white;
        margin-bottom:                          0;
        line-height:                            2;
        font-size:                              $nanofont;
        font-weight:                            bold; }

.footer-li {
        line-height:                            2;
        a {
                color:                          $white;
                &:hover, &:focus {
                        color:                  $white; } } }

.footer-form-container {
        margin:                                 0 auto 5rem auto;
        width:                                  300px;
        @media #{ $mediumup } {
                margin:                         0 0 5rem 0;
                width:                          auto;
                justify-self:                   end; } }
.footer-subscribe-p {
        color:                                  $white;
        margin-bottom:                          0;
        line-height:                            2;
        font-size:                              $nanofont; }

.footer-form-input {
        background:                             none;
        border:                                 none;
        outline:                                none;
        border-bottom:                          1px solid $white;
        padding-bottom:                         0.5rem;
        @media #{ $slargeup } {
                width:                          250px; }
        &::placeholder {
                font-size:                      $nanofont;
                color:                          #C0C0C0; } }


.footer-form-btn {
        background:                             $white;
        padding:                                0.5rem 1.5rem;
        font-size:                              0.7rem;
        margin-left:                            0.5rem; }

.footer-copyright-p {
        font-size:                              0.7rem;
        color:                                  $white;
        margin-bottom:                          0;
        padding-bottom:                         2rem; }

