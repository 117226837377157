//
//      // Icons

.prefix {
    margin-right:       7px;
    &.is-wider {
        margin-right:   14px; }
    &.is-even-wider {
        margin-right:   21px; } }

.suffix {
    margin-left:        7px;
    &.is-wider {
        margin-left:    14px; }
    &.is-even-wider {
        margin-left:    21px; } }
