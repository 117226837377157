//
//      // Animations

[animated] {
    animation-duration:     1s;
    animation-fill-mode:    both; }

[fade-in-up] {
    animation-name:         fade-in-up; }
[slide-down] {
    animation-name:         slide-down; }

@keyframes fade-in-up {
    0% {
        opacity:            0;
        transform:          translate3d(0, 8px, 0); }
    100% {
        opacity:            1;
        transform:          translate3d(0, 0, 0); } }

@keyframes slide-down {
    0% {
        transform:          translate3d(0, -8px, 0); }
    100% {
        transform:          translate3d(0, 0, 0); } }

@keyframes platinum-to-white {
    0% {
        background-color:   $platinum; }
    100% {
        background-color:   #fff; } }
