//
//  // Archive Pages (Posts & Pages)



.is-skills-all {
    .featured-content-list {
        li:not(:last-child) {
            margin-bottom:      $space;
            @media #{$mediumup} {
                margin-bottom:  $space-sm; } } } }

.is-list-all {
    .post-date, .reading-time {
        font-size:              $nanofont; } }

.archive-grid {
    display: grid;
    grid-gap: $space-sm;
    grid-template-columns:  repeat(auto-fit, minmax(272px, 1fr));
    @media #{$mediumup} {
        grid-template-columns:  repeat(auto-fit, minmax(210px, 1fr)); }
    @media #{$slargeup} {
        grid-template-columns:  repeat(auto-fit, minmax(272px, 1fr)); } }

.archive-image {
    width: 100%;
    height: 256px;
    object-fit: cover; }
