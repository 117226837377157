//
//  // Pricing page
.pricing-container {
    padding:                        1rem 0.5rem 0 0.5rem;
    max-width:                      1100px;
    margin:                         0 auto 4rem auto;
    @media #{ $smallup } {
        padding:                    1rem 1.5rem 0 1.5rem; }
    @media #{ $slargeup } {
        padding:                    2rem 5rem 0 5rem; }
    @media #{ $mlaptop } {
        padding:                    2rem 7rem 0 7rem; } }

.pricing-container1 {
    padding-bottom:                 0.5rem;
    border-bottom:                  1px solid $black;
    @media #{ $mediumup } {
        display:                    flex;
        align-items:                flex-end;
        justify-content:            space-between;
        width:                      99%; } }

.pricing-h2 {
    width:                          230px; }

.pricing-list {
    // display:                        flex
    // flex-wrap:                      wrap
    display:                        grid;
    grid-template-columns:          repeat(3, 1fr);
    margin-bottom:                  0;
    @media #{ $smediumup } {
        margin-bottom:              0.5rem; }
    li {
        font-size:                  0.7rem;
        @media #{ $slargeup } {
            font-size:              90%; } }
    a {
        color:                      $readablegray;
        &:focus, &:hover {
            color:                  $alttextcolor;
            padding-bottom:         0.5rem; }
        @media #{ $smallup } {
            &:focus, &:hover {
                border-bottom:          2px solid $alttextcolor;
                @media #{ $smediumup } {
                    padding-bottom:     1rem; } } } } }

.faq-container {
    margin-bottom:                      4rem; }
