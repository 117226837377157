//
//      // Backgrounds
.bg-brand {
    background-color:   $primarycolor; }
.bg-white {
    background-color:   #fff; }
.bg-platinum {
    background-color:   $platinum; }
.bg-alt {
    background-color:   #D1E7FE; }

// Colors
.co-brand {
    color:              $primarycolor; }
.co-white {
    color:              $white; }
.co-sweetgray {
    color:              $sweetgray; }
.co-readablegray {
    color:              $readablegray;
    align-item:         center; }
.co-readableblue {
    color:              $readableblue; }
.co-alttextcolor {
    color:              $alttextcolor; }
.co-black {
    color:              $black; }


