@font-face {
    font-family: 'DMSanRegular';
    font-style: normal;
    font-weight: normal;
    src: url('/uploads/fonts/DMSans-Regular.ttf') format('woff'); }

@font-face {
    font-family: 'DMSerifDisplay';
    font-style: normal;
    font-weight: normal;
    src: url('/uploads/fonts/DMSerifDisplay-Regular.ttf') format('woff'); }

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    src: url('/uploads/fonts/Inter-Regular.ttf') format('woff'); }

@font-face {
    font-family: 'Recoleta';
    font-style: normal;
    font-weight: normal;
    src: url('/uploads/fonts/Recoleta-Regular.otf') format('woff'); }
