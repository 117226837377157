//
//  // Home Page

// General homepage style
.home-sections-title {
    color:                          $readableblue;
    font-size:                      $picofont;
    letter-spacing:                 2px; }

.home-section-h2 {
    color:                          $alttextcolor;
    font-size:                      $largerfont;
    // width:                          240px
    line-height:                    1.5; }

//hero
.hero-container {
    padding:                        2rem 0.5rem 0;
    max-width:                      1100px;
    margin:                         0 auto;
    @media #{ $smallup } {
        padding:                    2rem 1.5rem 0;
        display:                    flex;
        justify-content:            space-between; }
    @media #{ $slargeup } {
        padding:                    2rem 3rem 0; }
    @media #{ $mlaptop } {
        padding:                    2rem 5rem 0; }
    @media only screen and (max-width: 620px) {
        flex-direction:             column; } }
.hero-text-container {
    padding-bottom:                 1rem;
    @media #{ $smallup } {
        padding-bottom:             0; }
    @media only screen and (max-width: 820px) {
        margin-bottom:                 40px; }
    @media only screen and (max-width: 671px) {
        margin-bottom:                 20px; } }
.hero-img-container {}

.hero-text-h1 {
    font-size:                      $largerfont;
    color:                          $black;
    letter-spacing:                 1.5px;
    width:                          350px;
    line-height:                    1.5;
    @media #{ $mediumup } {
        font-size:                  2.7rem; } }

.hero-text-p {
    font-size:                      $microfont;
    line-height:                    2;
    @media #{ $smallup } {
        width:                      265px; }
    @media #{ $mediumup } {
        width:                      301px; }
    @media only screen and (max-width: 620px) {
        width:                      100%; } }


.hero-text-btn {
    background:                     #2F2F2F;
    color:                          $white;
    cursor:                         pointer;
    padding:                        0.7rem 1rem;
    font-size:                      $nanofont;
    margin:                         0 20px 0 0;
    &:last-of-type {
        background:                 $readableblue;
        margin:                     0; }
    &:focus, &:hover {
        color:                      $white; }
    span {
        padding-left:               1rem; }
    &.black-variant {
        background:                 $alttextcolor; } }


// About Us Preview
.about-preview-container {
    background:                     #F8FFFD;
    @media #{ $smallup } {
        margin:                     2rem 0; }
    @media #{ $smediumup } {
        margin:                     0; } }
.about-preview-container2 {
    max-width:                      1100px;
    margin:                         0 auto;
    padding:                        1rem 0.5rem;
    @media #{ $smallup } {
        padding:                    1rem 1.5rem;
        display:                    flex;
        justify-content:            space-between; }
    @media #{ $slargeup } {
        padding:                    2rem 3rem; }
    @media #{ $mlaptop } {
        padding:                    2rem 5rem; }
    @media only screen and (max-width: 670px) {
        flex-direction:             column; } }
.about-preview-box-container {
    background:                     rgba(16, 175, 240, 0.1);
    // margin:                         1rem
    padding:                        1rem 1rem 2rem 1rem;
    @media #{ $xsmallup } {
        padding:                    3rem 2rem; }
    @media #{ $mediumup } {
        margin:                     1rem 0; } }

.about-preview-box-btn {
    cursor:                         pointer;
    color:                          $alttextcolor;
    border:                         1px solid $black;
    padding:                        0.7rem 1rem;
    margin-top:                     1.5rem;
    &:hover, &:focus {
        color:                      $alttextcolor; } }
.about-preview-text-container {
    padding:                        1rem;
    margin:                         0 auto;
    min-width:                      350px;
    max-width:                      460px;
    @media #{ $mediumup } {
        margin:                     0; }
    p {
        font-size:                      $microfont;
        line-height:                    1.5;
        @media #{ $mediumup } {
            font-size:                  $smallfont; } } }



.about-preview-text-p1 {
    color:                          $alttextcolor;
    @media #{ $mediumup } {
        line-height:                2; } }


.about-preview-text-p2 {
    color:                          $readablegray;
    @media #{ $mediumup } {
        line-height:                2; } }


.about-preview-text-p3 {
    color:                          $alttextcolor; }

.about-preview-text-p4 {
    color:                          $readablegray; }


// Our products

.products-container {
    padding:                        2rem 0.5rem;
    max-width:                      1100px;
    margin:                         0 auto;
    @media #{ $smallup } {
        padding:                    2rem 1.5rem; }
    @media #{ $slargeup } {
        padding:                    4rem 3rem; }
    @media #{ $mlaptop } {
        padding:                    4rem 5rem; } }

.product-container2 {
    border-bottom:                  1px solid $black;
    max-width:                      789px;
    @media #{ $smediumup } {
        display:                    flex;
        align-items:                flex-end;
        justify-content:            space-between; } }

.product-container2-h2 {
    padding-bottom:                 1rem;
    @media #{ $smediumup } {
        padding-bottom:             0;
        margin-bottom:              0; } }


.products-list {
    display:                        flex;
    flex-wrap:                      wrap;
    li {
        margin-right:               $space-xs;
        color:                      $readablegray;
        cursor:                     pointer;
        &:last-child {
            margin-right:           0; }
        @media #{ $xsmallup } {
            margin-right:           $space-md; }
        &.active, &:focus, &:hover {
            color:                  $alttextcolor;
            border-bottom:          2px solid $alttextcolor;
            @media #{ $smediumup } {} } } }

.slideshow-single {
        display:                    none; }
.slideshow-single-h3 {
    font-size:                      $microfont;
    color:                          $alttextcolor;
    margin-top:                     2rem;
    &::before, &::after {
        background-color:           $alttextcolor;
        content:                    "";
        display:                    inline-block;
        height:                     1px;
        position:                   relative;
        vertical-align:             middle;
        width:                      5%; }
    &::after {
        left:                       0.5em;
        margin-right:               -50%;
        display:                    none; } }

.slideshow-single2 {
    margin-top:                     1.5rem;
    @media #{ $smediumup } {
        display:                    flex;
        justify-content:            space-between; } }


.slideshow-single2-text {
    max-width:                      321px;
    margin-bottom:                  2rem;
    &.expe {
        max-width:                  380px; }
    @media #{ $smediumup } {
        margin-bottom:              0; }
    @media only screen and (max-width: 671px) {
        max-width:                  100%; } }
.slideshow-single2-h2 {
    font-size:                      $largefont;
    color:                          $alttextcolor;
    line-height:                    1.5;
    padding-bottom:                 1rem;
    margin-bottom:                  0;
    @media #{ $smediumup } {
        font-size:                  $largerfont; } }

.slideshow-single2-p {
    font-size:                      $microfont;
    color:                          $black; }

.slideshow-single2-p2 {
    font-size:                      $microfont;
    color:                          #999999; }

.slideshow-single2-btn {
    background:                     $alttextcolor;
    background:                     $readableblue;
    color:                          $white;
    padding:                        0.5rem 1rem; }

// Our Partner
.partners-container {
    background:                     $primarycolor; }

.partners-container2 {
    max-width:                      1100px;
    margin:                         1rem auto;
    padding:                        1.5rem 0.5rem;
    @media #{ $smallup } {
        padding:                    2rem 1.5rem; }
    @media #{ $slargeup } {
        padding:                    4rem 3rem; }
    @media #{ $mlaptop } {
        padding:                    4rem 5rem; }
    h2 {
        padding-bottom:                 1rem; } }


.partners-logo-container {
    padding:                        3rem;
    display:                        flex;
    flex-wrap:                      wrap;
    align-items:                    center;
    flex-direction:                 column;
    // justify-items:                  center
    justify-content:                center;
    align-content:                  center;
    // width:                          300px
    gap:                                40px;
    img {
        margin-bottom:              $space-md;
        &:last-child {
            margin-bottom:          0; } }
    @media #{ $smediumup } {
        flex-direction:             row;
        img {
            &:nth-of-type(2) {
                margin-top:             -25px; }
            &:nth-of-type(5) {
                margin-top:             -30px; }
            margin-right:               $space-md;
            margin-bottom:              0;
            &:last-child {
                margin-right:           0; } } } }


.press-container {
    margin:                             5rem 0 1rem 0; }

.press-grid-container {
    display:                            grid;
    grid-gap:                           2rem;
    @media #{ $smediumup } {
        grid-template-columns:          1fr 1fr; } }
.press-grid-item {
    max-width:                          400px;
    margin:                             0 auto;
    padding:                            1rem;
    background:                         rgba(15,175,240,0.1);
    @media #{ $xsmallup } {
        padding:                        2rem; }
    @media #{ $smediumup } {
        margin:                         0; } }

.press-review {
    font-size:                          $microfont;
    line-height:                        2;
    color:                              $alttextcolor; }

.press-name {
    font-weight:                        bold;
    font-size:                          $microfont;
    line-height:                        1.5;
    color:                              $alttextcolor; }

.press-location {
    color:                              $readablegray;
    font-size:                          $picofont; }

.press-img {
    border:                             3px solid #0FAFF0;
    width:                              60px;
    border-radius:                      50%; }

// our blog

.blog-container {
    &.home-variant {
        padding:                        2rem 0 !important; }
    padding:                            1rem 0.5rem 0 0.5rem;
    max-width:                          1100px;
    margin:                             2rem auto;
    @media #{ $smallup } {
        padding:                        2rem 1.5rem; }
    @media #{ $slargeup } {
        padding:                        2rem 3rem; }
    @media #{ $mlaptop } {
        padding:                        2rem 5rem; }
    h2 {
        width:                          250px; } }

.blog-grid-container {
    display:                            grid;
    grid-gap:                           2rem;
    padding-top:                        2rem;
    @media #{ $smediumup } {
        grid-template-columns:          1fr 1fr; } }
.blog-grid-item {
    max-width:                          400px;
    height:                             243px;
    background:                         #E1F7FC;
    margin:                             0 auto;
    @media #{ $smediumup } {
        margin:                         0; } }

.blog-grid-item-text {
    max-width:                          250px;
    padding:                            1rem; }

.blog-name-avatar {
    width:                              160px; }

.blog-img {
    width:                              30%;
    object-fit:                         cover;
    @media #{ $smediumup } {}
    @media #{ $slargeup } {
        width:                          auto; } }

.blog-blurred-p {
    color:                              $readablegray;
    font-size:                          0.7rem; }

.blog-p {
    font-size:                      $nanofont;
    color:                          $alttextcolor; }
