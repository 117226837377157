//
//  // Institue Page

.launch {
    &>div {
        flex: 0 0 50%;
        &:first-of-type {
            padding-right: 10px; }
        &:first-of-type {
            padding-left: 10px;
            object-fit:     contain; } }

    img {
        width {} } }
