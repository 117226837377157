//
//      // Positioning

.absolute-zero {
    position:           absolute;
    top:                0;
    right:              0;
    bottom:             0;
    left:               0; }
.absolute-middle {
    position:           absolute;
    top:                50%;
    left:               50%;
    transform:          translate( -50%, -50% ); }

.pos-rel {
    position:           relative; }
.pos-stk {
    position:           sticky;
    position:           -webkit-sticky;
    &.t-0 {
        top:            0; } }

// Backgrounds
.bg-h {
    height:             522px; }

// Border none
.b-0 {
    border: 0px; }
// Display
.d-blk {
    display:            block; }
.d-ibl {
    display:            inline-block; }
.d-flx {
    display:            flex; }
.flx-wrap {
    flex-wrap:          wrap; }
.flx-dir-c {
    flex-direction:     column; }
.al-i-fs {
    align-items:        flex-start; }
.al-i-fe {
    align-items:        flex-end; }
.al-i-c {
    align-items:        center; }

.j-c-sb {
    justify-content:    space-between; }

.j-c-fe {
    justify-content:    flex-end; }


.flx-col-100 {
    display:            flex;
    flex-direction:     column;
    height:             100%; }
.flex-center-center {
    display:            flex;
    align-items:        center;
    justify-content:    center; }
.grid-center-center {
    display:            grid;
    align-items:        center;
    justify-content:    center; }


// Margins
.m-0 {
    margin:             0; }
.m-0-a {
    margin:             0 auto; }
.my-0 {
    margin-top:         0;
    margin-bottom:      0; }
.my-1 {
    margin-top:         1rem;
    margin-bottom:      1rem; }
.my-1-s {
    margin-top:         1rem;
    margin-bottom:      1rem;
    @media #{ $mediumup } {
        margin-top:      0;
        margin-bottom:   0; } }

.mx-1 {
    margin-left:        5rem;
    margin-right:       5rem; }

.mt-0 {
    margin-top:         0; }
.mt-h {
    margin-top:         0.5rem; }
.mt-1 {
    margin-top:         1rem; }
.mt-1h {
    margin-top:         1.5rem; }
.mt-2 {
    margin-top:         2rem; }
.mt-2h {
    margin-top:         2.5rem; }
.mt-3 {
    margin-top:         3rem; }

.mx-auto {
    margin-right:       auto;
    margin-left:        auto; }
.mr-h {
    margin-right:       0.5rem; }
.mr-1 {
    margin-right:       1rem; }
.mr-1h {
    margin-right:       1.5rem; }

.ml-h {
    margin-left:       0.5rem; }
.ml-1 {
    margin-left:       1rem; }

.mb-0 {
    margin-bottom:      0; }
.mb-hh {
    margin-bottom:      0.25rem; }
.mb-h {
    margin-bottom:      0.5rem; }
.mb-1 {
    margin-bottom:      1rem; }
.mb-2 {
    margin-bottom:      2rem; }
.mb-3 {
    margin-bottom:      3rem; }

// Paddings
.p-1 {
    padding:            1rem; }
.p-1h {
    padding:            1.5rem; }
.p-2 {
    padding:            2rem; }

.py-0 {
    padding-top:        0;
    padding-bottom:     0; }
.py-h {
    padding-top:        0.5rem;
    padding-bottom:     0.5rem; }
.py-1 {
    padding-top:        1rem;
    padding-bottom:     1rem; }
.py-2 {
    padding-top:        2rem;
    padding-bottom:     2rem; }

.px-0 {
    padding-left:       0;
    padding-right:      0; }
.px-h {
    padding-left:       .5rem;
    padding-right:      .5rem; }
.px-1 {
    padding-left:       1rem;
    padding-right:      1rem; }

.pt-h {
    padding-top:        0.5rem; }
.pt-1 {
    padding-top:        1rem; }
.pt-1h {
    padding-top:        1.5rem; }
.pt-2 {
    padding-top:        2rem; }

.pb-h {
    padding-bottom:     0.5rem; }
.pb-1 {
    padding-bottom:     1rem; }
.pb-1h {
    padding-bottom:     1.5rem; }
.pb-2 {
    padding-bottom:     2rem; }

.p-1s {
    padding:            0 1rem; }
.pl-0 {
    padding-left:       0; }
.pl-q {
    padding-left:       0.25rem; }
.pl-h {
    padding-left:       0.5rem; }
.pl-1 {
    padding-left:       1rem; }
.pl-2 {
    padding-left:       2rem; }

.pr-q {
    padding-right:      0.25rem; }
.pr-h {
    padding-right:      0.5rem; }

.pt-1-laptop {
    @media #{ $slaptop } {
        padding-top:    1rem; } }
// width
.w-120 {
    width:              120px; }

// Media Query Utilities
.show-smallup {
    display:            none;
    ul {
        align-items:    end; }
    button {
        transform:      translateY(8px); }
    @media only screen and (min-width: 930px) {
        display:        block; } }

.show-mediumup {
    display:            none;
    @media #{ $mediumup } {
        display:        block; } }

.show-slargeup {
    display:            none;
    @media #{ $largeup } {
        display:        block; } }
.show-mlaptop {
    display:            none;
    @media #{ $mlaptop } {
        display:        block; } }

.hide-mlaptop {
    display:            block;
    @media #{ $mlaptop } {
        display:        none; } }


.hide-slargeup {
    display:            block;
    @media #{ $largeup } {
        display:        none; } }

.hide-mediumup {
    display:            block;
    @media #{ $mediumup } {
        display:        none; } }

.hide-smallup {
    display:            block;
    @media only screen and (min-width: 930px) {
        display:        none; } }

.hide-mediumup {
    display:            block;
    @media #{ $mediumup } {
        display:        none; } }

.mq-mt-1 {
    margin-top:         1rem;
    @media #{ $mediumup } {
        margin-top:     0; } }

.mq-mb-1 {
    margin-bottom:      2rem;
    @media #{ $mediumup } {
        margin-bottom:  0; } }
.mq-pt-2 {
    padding-top:        0;
    padding-bottom:     0;
    @media #{ $smallup } {
        padding-top:    4rem;
        padding-bottom: 3rem; } }


// Stacking
.z-depth-1 {
    z-index:            1; }


// Opacity
.opacity-7 {
    opacity:            0.7; }

// Screen Reader Text
//  // The .sr class ensure element is available in the DOM but only to screen readers. The whitespace: nowrap rule ensures accessible texts aren't smushed off screen.
//  // @link https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
.sr:not(:focus):not(:active) {
    position:            absolute;
    width:               1px;
    height:              1px;
    padding:             0;
    margin:              -1px;
    border:              0;
    clip-path:           inset(100%);
    overflow:            hidden;
    white-space:         nowrap; }




