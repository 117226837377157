//
//      // Headings

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
    margin-top:         $space-xs;
    margin-bottom:      $space-xxs;
    font-weight:        $bold;
    line-height:        $headinglh; }
h1, .h1 {
    font-family:        $headingfont;
    font-size:          1.6rem;
    @media #{ $smallup } {
        font-size:      2.08rem; } }
h2, .h2 {
    font-family:        $secondheadingfont;
    font-size:          1.8rem;
    @media #{ $smallup } {
        font-size:      1.96rem; } }
h3, .h3 {
    font-family:        $secondheadingfont;
    font-size:          1.42rem;
    line-height:        1.5rem;
    margin:             0; }

h4, .h4 {
    font-family:        $secondheadingfont;
    font-size:          0.96rem;
    text-transform:     uppercase;
    letter-spacing:     $headingls; }
h3 + h4, .h3 + .h4 {
    margin-top:         $space; }

.is-all-caps {
    text-transform:     uppercase;
    letter-spacing:     $headingls; }

.tx-c {
    text-align:         center; }

.picotext {
    font-size:          $picofont; }
.nanotext {
    font-size:          $nanofont; }
.microtext {
    font-size:          $microfont; }
.smalltext {
    font-size:          $smallfont; }
.mediumtext {
    font-size:          $mediumfont; }
.largetext {
    @media #{ $mediumup } {}
    font-size:          $largefont; }

.lh-0 {
    line-height:        0; }
.lh-1 {
    line-height:        1; }

.ff-body {
    font-family:        $bodyfont; }
.fw-normal {
    font-weight:        $normal; }
.fw-bold {
    font-weight:        $bold; }

.fs-body {
    font-style:         $bodyfont; }
