//
//      // Images

.has-avatar {
    display:                table;
    &.is-small {
        width:              90px;
        height:             90px; }
    &.is-medium {
        width:              120px;
        height:             120px; } }

.avatar {
    border-radius:          50%;
    &.is-nano {
        width:              36px;
        height:             36px; }
    &.is-micro {
        width:              45px;
        height:             45px; }
    &.is-small {
        width:              90px;
        height:             90px; }
    &.is-medium {
        width:              120px;
        height:             120px; } }

.obj-fit-cover {
    object-fit:             cover; }
