//
//      // Decorations on Elements

.has-fullstop {
    @media #{ $mediumup } {
        position:               relative;
        display:                inline!important;
        &::after {
            position:           absolute;
            content:            '';
            width:              5px;
            height:             5px;
            right:              -12px;
            bottom:             7px;
            background-color:   #34A9FF;
            opacity:            0.4;
            border-radius:      3px; } } }

.border-top {
    border-top:             $borderline; }
.border-right {
    border-right:           $borderline; }
.border-bottom {
    border-bottom:          $borderline; }

.separator {
    margin:                 0 $space-xxs;
    &::after {
        content:            '\00B7';
        color:              inherit; } }

.box-attention-grabber {
    position:               relative;
    overflow:               hidden;
    &::before {
        content:            '';
        position:           absolute;
        right:              -10px;
        bottom:             -10px;
        width:              25px;
        height:             25px;
        border-radius:      50%;
        background-color:   $primarycolor; } }
