//
//      // Buttons

button {
    cursor:                 pointer;
    background:             none;
    border:                 0;
    padding:                inherit;
    font:                   inherit;
    &.view-more {
        padding:            $space-xs; }
    &:active, &:focus, &:hover {
        border:             0;
        outline:            transparent; } }

.btn-link {
    border:                 1px solid white;
    border-radius:          8px;
    padding:                1.5rem 2rem;
    color:                  $white;
    font-size:              $basefont;
    font-family:            $bodyfont;
    font-weight:            $medium;
    line-height:            1px;
    &:focus, &:hover {
        color:              $primarycolor;
        background:         $white;
        text-decoration:    none; } }


.home-link {
    border:                 1px solid $primarycolor;
    border-radius:          8px;
    padding:                1.5rem 2rem;
    color:                  $primarycolor;
    font-size:              $basefont;
    font-family:            $bodyfont;
    font-weight:            $medium;
    line-height:            0;
    &:focus, &:hover {
        color:              $white;
        text-decoration:    none;
        background-color:   $primarycolor; } }

